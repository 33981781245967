import {useContext, useEffect, useState} from "react";
import {fetchHarvestInvoices} from "./api";
import {UserContext} from "./App";

let numberFormat = Intl.NumberFormat('sv-SE', {style: 'decimal'});

export const Invoices = () => {
    const user = useContext(UserContext);
    const [invoices, setInvoices] = useState([]);
    useEffect(() => {
        fetchHarvestInvoices(user?.token)
            .then(res => setInvoices(res))
    }, [user]);

    console.log("invoices", invoices)
    return (
        <div style={{padding: "1em"}}>
            <h2>Invoices</h2>
            <table width={"100%"}>
                <thead>
                <tr>
                    <th>Harvest Invoice Id</th>
                    <th>Customer</th>
                    <th>Harvest Created Date</th>
                    <th>Harvest Updated Date</th>
                    <th>Amount</th>
                    <th>Fortnox Created Date</th>
                    <th>Fortnox Document No</th>
                </tr>
                </thead>
                <tbody>
                {invoices.map(i => (
                    <tr key={i.harvest_invoice_id}>
                        <td>{i.harvest_invoice_id}</td>
                        <td>{i.client.name}</td>
                        <td>{i.harvest_created_date}</td>
                        <td>{i.harvest_updated_date}</td>
                        <td>{numberFormat.format(i.harvest_invoice.amount)}</td>
                        <td>{i.fortnox_created_date}</td>
                        <td>{i.fortnox_document_number}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}